import React from 'react'

const MicOff = ({size="24px"}) => {
    return (
        <svg  width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.7199 5.39023C10.7199 4.73023 11.2599 4.19023 11.9199 4.19023C12.5799 4.19023 13.1199 4.73023 13.1199 5.39023L13.1099 9.30023L14.9199 11.0902V5.49023C14.9199 3.83023 13.5799 2.49023 11.9199 2.49023C10.3799 2.49023 9.12992 3.65023 8.95992 5.14023L10.7199 6.90023V5.39023ZM18.9199 11.4902H17.2199C17.2199 12.0702 17.1199 12.6202 16.9499 13.1302L18.2199 14.4002C18.6599 13.5202 18.9199 12.5302 18.9199 11.4902ZM4.32992 3.35023L2.91992 4.76023L8.91992 10.7602V11.4902C8.91992 13.1502 10.2599 14.4902 11.9199 14.4902C12.1499 14.4902 12.3599 14.4602 12.5699 14.4102L14.2299 16.0702C13.5199 16.4002 12.7299 16.5902 11.9199 16.5902C9.15992 16.5902 6.61992 14.4902 6.61992 11.4902H4.91992C4.91992 14.9002 7.63992 17.7202 10.9199 18.2102V21.4902H12.9199V18.2102C13.8299 18.0802 14.6899 17.7602 15.4699 17.3102L19.6699 21.5102L21.0799 20.1002L4.32992 3.35023Z" fill="#fff" />
        </svg>

    )
}

export default MicOff
import socketManager from "../socketManager/SocketManager";

const configuration = {
  iceServers: [
    {
      urls: 'stun:stun.relay.metered.ca:80',
    },
    {
      urls: 'turn:global.relay.metered.ca:80',
      username: 'e84ad577c9836ae72e23dc2b',
      credential: 'B1K3fxw/DsSUgLj+',
    },
    {
      urls: 'turn:global.relay.metered.ca:80?transport=tcp',
      username: 'e84ad577c9836ae72e23dc2b',
      credential: 'B1K3fxw/DsSUgLj+',
    },
    {
      urls: 'turn:global.relay.metered.ca:443',
      username: 'e84ad577c9836ae72e23dc2b',
      credential: 'B1K3fxw/DsSUgLj+',
    },
    {
      urls: 'turns:global.relay.metered.ca:443?transport=tcp',
      username: 'e84ad577c9836ae72e23dc2b',
      credential: 'B1K3fxw/DsSUgLj+',
    },
  ],
};

export const fetchIceServers = async () => {
  try {
    const response = await fetch(
      'https://jamrack.metered.live/api/v1/turn/credentials?apiKey=35d4649747f91c2c9f408c986a2dc82d45e0',
      {
        method: 'GET',
      },
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error fetching ICE Servers:', error);
  }
};

const useWebRTC = () => {


  const createPeerConnectionCreator = async (
    stream,
    userId,
    mainRoom,
    peerConnections
  ) => {
    const result = await fetchIceServers();
    console.log('result', result);

    // const configuration = {
    //   iceServers: result,
    // };
    const pc = new RTCPeerConnection(configuration);

    if (stream && stream.getTracks().length > 0) {
      stream.getTracks().forEach((track) => {
        pc.addTrack(track, stream);
      });
    } else {
      console.log("No valid tracks in the local stream.");
    }

    pc.onicecandidate = (event) => {
      if (event.candidate) {
        socketManager.sendIceCandidate(
          userId,
          event.candidate,
          mainRoom,
          "artist"
        );
      }
    };

    pc.oniceconnectionstatechange = () => {
      console.log("ICE Connection State sender:", pc.iceConnectionState);
    };

    const offer = await pc.createOffer();
    await pc.setLocalDescription(offer);
    socketManager.sendOffer(userId, offer, mainRoom);
    peerConnections.current[userId] = pc;
  };


  const toggleAudio = (isAudio, stream) => {
    if (stream && stream.getAudioTracks) {
      const audioTracks = stream.getAudioTracks()[0];
      if (audioTracks) {
        // audioTracks.forEach((track) => {
        //   track.enabled = isAudio;
        // });
        audioTracks.enabled = !audioTracks.enabled;
        console.log(
          `Audio ${isAudio ? "enabled" : "disabled"} for ${
            audioTracks
          } track(s).`
        );
      } else {
        console.warn("No audio tracks found in the localStream.");
      }
    } else {
      console.error("Invalid localStream or no audio tracks available.");
    }
  };


  const handleSignalingData = async (data) => {
    const { type, userId, answer, candidate } = data;

    switch (type) {
      case "answer":
        if (data.peerConnections.current[userId]) {
          const pc = data.peerConnections.current[userId];
          if (pc) {
            await pc.setRemoteDescription(new RTCSessionDescription(answer));
          }
        }
        break;

      case "icecandidate":
        const iceCandidate = new RTCIceCandidate(candidate);
        if (data.peerConnections.current[userId]) {
          const pc = data.peerConnections.current[userId];
          pc.addIceCandidate(iceCandidate);
        }
        break;

      default:
        break;
    }
  };


  const closeAllPeerConnections = (peerConnections) => {
    Object.keys(peerConnections.current).forEach((userId) => {
      const pc = peerConnections.current[userId];
      if (pc) {
        pc.getSenders().forEach((sender) => {
          if (sender.track) {
            sender.track.stop();
          }
        });
        pc.ontrack = null;
        pc.onicecandidate = null;
        pc.oniceconnectionstatechange = null;
        pc.onremovetrack = null;
        pc.onsignalingstatechange = null;
        pc.close();
        // Optionally remove the peer connection from the list
        delete peerConnections.current[userId];
        console.log(`Peer connection with user ${userId} closed.`);
      }
    });
    peerConnections.current = {};
  };

  return {
    createPeerConnectionCreator,
    toggleAudio,
    handleSignalingData,
    closeAllPeerConnections,
  };
};

export default useWebRTC;

// config/key.js

// Development
// export const backendUrl = "http://localhost:7002";

// Staging
// export const backendUrl = "http://localhost:8002";

// Production
export const backendUrl = "https://prod-socket.jamrack.net";

// for jam rack server url
export const thirdPartServerUrl = 'https://prod-api.jamrack.net';

// for socket connection
export const socketConnectUrl = 'https://prod-socket.jamrack.net';

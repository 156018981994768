import React from 'react';
import './MediaRecord.css'

const EndStreamPage = () => {
  return (
    <div className='end-stream-page'>
        <h1> Meeting Ended </h1>
    </div>
  )
}

export default EndStreamPage
